import * as React from "react"
import Seo from "../components/seo"
import "../assets/css/style.css"
import "../assets/css/layout.css"
import "../assets/css/benefits-style.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { organizationSchema } from "../const/schemas"
import Benefits from "../components/benefits/benefits"
import { languages } from "../const/languages"

const BenefitsPage = () => <Benefits lang={languages.EN} />

export const Head = () => {
  return (
    <>
      <Seo
        title="Discover the power of benefits from AR solutions."
        description="See how augmented reality can help your brand stand out in the market and provide your customers with a sustainable and engaging shopping experience."
        canonicalURL="https://staging-area.ar-labs.io/benefits"
      />
      <script type="application/ld+json">{organizationSchema}</script>
    </>
  )
}

export default BenefitsPage
